import * as React from "react";
import styled from "styled-components";
import { OfferHeader } from "./OfferHeader";
import { OfferFooter } from "./OfferFooter";
import bgDesktop from "../../images/SpecialOffer/backgrounds/bg-desktop-daily.png";
import bgTablet from "../../images/SpecialOffer/backgrounds/bg-tablet-daily.png";
import bgMobile from "../../images/SpecialOffer/backgrounds/bg-mobile-daily.png";
import bgNarrow from "../../images/SpecialOffer/backgrounds/bg-narrow-daily.png";
import {
  Lerp,
  S3BucketPrefix,
  ScreenSizes,
  screenWidth,
  useScreenSize,
} from "../../shared-code";
import { ExpirationTypes } from "./ExpirationBadge";
import DailyOfferFooter from "./DailyOfferFooter";
import { DailyOrSpecialOffer, award } from "../DataModels";

const OuterWrapper = styled.div`
  position: relative;
  max-width: 685px;
  height: 315px;
`;

const Wrapper = styled.div<{ consumed: boolean }>`
  position: relative;
  width: 100%;
  max-width: 685px;
  height: 315px;
  background-position: right;
  background-size: cover;
  border-radius: 8px;
  cursor: ${(props) => (props.consumed ? "arrow" : "pointer")};
  justify-self: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: ${ScreenSizes.tablet}) {
    background: url(${bgTablet});
    background-size: cover;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    background: url(${bgMobile});
    background-size: cover;
    background-position: right;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    background: url(${bgNarrow});
    background-size: cover;
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 235px;
  gap: 20px;
  width: 100%;
  z-index: 1;

  @media (max-width: ${ScreenSizes.narrow}) {
    height: 165px;
    margin-top: 30px;
  }
`;

const OfferImage = styled.img<{ consumed: boolean }>`
  height: 240px;
  opacity: ${(props) => (props.consumed ? 0.5 : 1)};

  @media (max-width: ${ScreenSizes.narrow}) {
    height: 145px;
  }
`;

const Awards = styled.div`
  position: absolute;
  right: 10px;
  top: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  z-index: 1;

  @media (max-width: ${ScreenSizes.narrow}) {
    top: 38px;
  }
`;

const AwardName = styled.span`
  color: #fff;
  text-align: right;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  z-index: 1;

  @media (max-width: ${ScreenSizes.tablet}) {
    font-size: 20px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 24px;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    font-size: 20px;
  }
`;

const AwardAmount = styled.span`
  color: #fff;
  text-align: right;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (max-width: ${ScreenSizes.tablet}) {
    font-size: 28px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 32px;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    font-size: 28px;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: -50%;
  min-height: 315px;
  width: 150%;
  background-repeat: no-repeat;

  @media (max-width: ${ScreenSizes.tablet}) {
    background: url(${bgTablet});
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    background: url(${bgMobile});
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    background: url(${bgNarrow});
  }
`;

const PlaneImage = styled.img`
  position: absolute;
  top: -180px;
  left: ${Lerp(1025, 1280, 30, 138, Math.min(1280, screenWidth))}px;
  height: 660px;

  @media (max-width: ${ScreenSizes.tablet}) {
    left: ${Lerp(800, 1024, -20, 50, Math.min(1024, screenWidth))}px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    left: ${Lerp(501, 800, -35, 165, Math.min(1024, screenWidth))}px;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    left: ${Lerp(300, 500, -20, 50, Math.min(1024, screenWidth))}px;
    top: -100px;
    height: 510px;
  }
`;

export interface DailyOfferCardProps {
  offer: DailyOrSpecialOffer;
  purchaseFn: () => void;
  onClick: () => void;
}

export default (props: DailyOfferCardProps) => {
  if (props.offer.expires != undefined && props.offer.expires <= 0) {
    return null;
  }
  const localizedAmount = Intl.NumberFormat("en-US").format(
    props.offer.awards[0]?.amount || 0
  );

  return (
    <OuterWrapper>
      <OfferHeader
        loyaltyPoints={props.offer.loyaltyPoints}
        expiration={props.offer.expires}
        serverOffset={props.offer.serverOffset}
        expirationType={ExpirationTypes.refresh}
        stickerText={props.offer.stickerText}
      />
      <Wrapper onClick={props.onClick} consumed={props.offer.consumed || false}>
        <Awards>
          <AwardName>{props.offer.awards[0].text}</AwardName>
          <AwardAmount>{localizedAmount}</AwardAmount>
        </Awards>
        <CardBody>
          <OfferImage
            src={props.offer.image_url}
            consumed={props.offer.consumed || false}
            alt=""
          />
        </CardBody>
        <BackgroundImage src={bgDesktop} />
        <DailyOfferFooter
          {...props.offer.price}
          consumed={props.offer.consumed || false}
        />
      </Wrapper>
    </OuterWrapper>
  );
};
