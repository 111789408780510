import * as React from "react";
import styled from "styled-components";
import backgroundImage from "../images/web-bg.jpg";
import {
  AccountInfo,
  Currencies,
  DailyOrSpecialOffer,
  GoldOffer,
  LoyaltyPointOffer,
  Offers,
} from "../components/DataModels";
import { ContentBlock } from "../components/ContentBlock";
import { GoldCard } from "../components/shop/GoldCard";
import "../styles/shared.css";
import PageWrapper from "../components/shared/PageWrapper";
import ContentDiv from "../components/shared/ContentDiv";
import {
  LocalStorageInterface as LSI,
  ScreenSizes,
  configureLogoutTimer,
  createLogOutTimer,
  getShortTimeString,
  isSmallScreen,
  parseActiveAccount,
} from "../shared-code";
import SpecialOfferCard, {
  SpecialOfferCardProps,
} from "../components/shop/SpecialOfferCard";
import DailyOfferCard, {
  DailyOfferCardProps,
} from "../components/shop/DailyOfferCard";
import { AppServerAPI, WardenAPI } from "../api-calls";
import starPointIcon from "../images/StarPointIcon.png";
import goldIcon from "../images/SpecialOffer/gold.png";
import silverIcon from "../images/SpecialOffer/silver.png";
import LoyaltyOfferCard from "../components/shop/LoyaltyOfferCard";
import { ExpirationTypes } from "../components/shop/ExpirationBadge";
import ConfirmationPopover, {
  ComponentProps,
  ConfirmationSteps,
} from "../components/shop/ConfirmationPopover";
import PlaceholderCard from "../components/shared/PlaceholderCard";
import CustomTimer from "../components/shared/CustomTimer";
import { FakeStore } from "../FAKEDATA";
import FlightPassSection from "../components/shop/FlightPassSection";

const Wrapper = styled.main`
  width: 100%;
  min-height: 100%;
  height: 100%;
  background: url(${backgroundImage});
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  &::before {
    content: "";
    width: 100%;
    min-height: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    background-attachment: fixed;
  }
`;

const DailyOrSpecialCardGrid = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 55px;

  @media (max-width: ${ScreenSizes.mobile}) {
    grid-template-columns: 1fr;
  }
`;

const LoyaltyCardGrid = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;

  /* @media (max-width: ${ScreenSizes.laptop}) {
    grid-template-columns: 1fr 1fr 1fr;
  } */

  @media (max-width: ${ScreenSizes.tablet}) {
    grid-template-columns: 1fr 1fr;
    padding: 20px 5px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    grid-template-columns: 1fr;
  }
`;

const GoldCardGrid = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  width: 100%;

  @media (max-width: ${ScreenSizes.tablet}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    grid-template-columns: 1fr;
  }
`;

declare global {
  const XBuyButtonWidget: any;
  const XPayStationWidget: any;
}

function openPayStation(
  accessToken: string,
  sandbox: boolean,
  callbackFn: () => void
) {
  // https://developers.xsolla.com/doc/pay-station/integration-guide/open-payment-ui/

  var options = {
    access_token: accessToken,
    sandbox: sandbox,
  };
  var s = document.createElement("script");
  s.type = "text/javascript";
  s.async = true;
  s.src = "https://static.xsolla.com/embed/paystation/1.2.3/widget.min.js";

  s.addEventListener(
    "load",
    function (e) {
      XPayStationWidget.init(options);
      XPayStationWidget.open();
      XPayStationWidget.on(XPayStationWidget.eventTypes.CLOSE, () => {});

      XPayStationWidget.on(
        XPayStationWidget.eventTypes.STATUS_DONE,
        callbackFn
      );
    },
    false
  );
  var head = document.getElementsByTagName("head")[0];
  head.appendChild(s);
}

const TitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;
  margin-top: 10px;

  & h1 {
    text-transform: uppercase;
    font-size: 42px;
    font-weight: 600;
    text-align: left;
    margin-block: 0;
  }

  & h3 {
    font-size: 24px;
    font-weight: 400;
    text-align: left;
    max-width: 800px;
    line-height: 140%;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    margin-bottom: 30px;

    & h1,
    h3 {
      margin-inline: auto;
      text-align: center;
    }
  }
`;

const LoyaltyPointHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding-bottom: 20px;

  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction: column;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    align-items: center;
    gap: 10px;
  }
`;

const LoyaltyPointHeaderText = styled.div`
  max-width: 820px;
  font-size: 20px;
  line-height: 140%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;

  @media (max-width: ${ScreenSizes.narrow}) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const LoyaltyPointImg = styled.img`
  height: 44px;
  margin-block: auto;

  @media (max-width: ${ScreenSizes.narrow}) {
    display: none;
  }
`;

const LoyaltyPointImgSmall = styled.img`
  height: 44px;
  margin-block: auto;
  display: none;

  @media (max-width: ${ScreenSizes.narrow}) {
    display: block;
  }
`;

const AccentColor = styled.span`
  color: rgba(255, 141, 0, 1);
`;

const ShopTimer = styled.div`
  display: inline-flex;
  height: 35px;
  padding: 3px 16px 3px 10px;
  align-items: center;
  gap: 6px;
  border-radius: 36px;
  background: rgba(0, 0, 0, 0.7);
  font-weight: 600;
  font-size: 18px;
  color: white;

  & span {
    padding-top: 3px;
    width: 60px;
  }
`;

const TimerImg = styled.img`
  height: 20px;
  aspect-ratio: 1 / 1;
`;

const SubHeaderWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 60px;
`;

const SubHeaderResource = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 13px;

  & span {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }

  & img {
    height: 30px;
  }
`;

interface MyState {
  accounts: AccountInfo[] | undefined;
  activeAccountId: string | undefined;
  timeOutNumber: number | undefined;
  errorMessage: string | undefined;
  offers: Offers | undefined;
  currencies: Currencies | undefined;
  awaitingOffers: boolean;
  confirmationData: {
    step: string;
    closeFn: () => void;
    purchaseFn?: () => void;
    itemName?: string;
    starPointsRequired?: number;
    starPointsAwarded?: number;
  };
  starPointOfferProcessing: boolean;
  showLogin?: number | undefined;
}

export default class Shop extends React.Component<{}, MyState> {
  state: MyState = {
    accounts: LSI.getAccounts(),
    activeAccountId: LSI.getActiveAccountId(),
    timeOutNumber: undefined,
    errorMessage: undefined,
    offers: LSI.getOffers(),
    currencies: LSI.getCurrencies(),
    awaitingOffers: false,
    confirmationData: {
      step: ConfirmationSteps.hidden,
      closeFn: () => {
        const newConfirmationData = this.state.confirmationData;
        newConfirmationData.step = ConfirmationSteps.hidden;
        this.setState({ confirmationData: newConfirmationData });
      },
      purchaseFn: undefined,
      itemName: undefined,
      starPointsRequired: undefined,
      starPointsAwarded: undefined,
    },
    starPointOfferProcessing: false,
    showLogin: undefined,
  };

  componentDidMount(): void {
    const email = LSI.getEmailAddress(false);
    const refreshToken = LSI.getRefreshToken();
    const shouldDelete = refreshToken == undefined;
    const starToken = LSI.getStarToken(shouldDelete);
    const starTokenExpiration = LSI.getStarTokenExpiration(false);
    if (
      starTokenExpiration != undefined &&
      starTokenExpiration < Date.now() &&
      starToken != undefined &&
      email != undefined &&
      refreshToken != undefined
    ) {
      WardenAPI.refreshStarToken(email, starToken, refreshToken);
    }
    const activeAccountId = this.state.activeAccountId;
    if (activeAccountId != undefined && this.state.awaitingOffers == false) {
      this.setState({ awaitingOffers: true });
      AppServerAPI.getXsollaOffers(
        activeAccountId,
        (offers, currencies, isSandboxMode) => {
          LSI.setOffers(offers, activeAccountId);
          LSI.setCurrencies(currencies, activeAccountId);
          LSI.setIsSandboxMode(isSandboxMode);
          this.setState({
            offers: offers,
            currencies: currencies,
            awaitingOffers: false,
          });
        }
      );
    }
    if (this.state.timeOutNumber != undefined) {
      window.clearTimeout(this.state.timeOutNumber);
    }
    const timeOutNumber = configureLogoutTimer();
    if (timeOutNumber != undefined) {
      this.setState({ timeOutNumber: timeOutNumber });
    }
  }

  componentWillUnmount(): void {
    if (this.state.timeOutNumber != undefined) {
      window.clearTimeout(this.state.timeOutNumber);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<MyState>
  ): void {
    if (prevState.activeAccountId != this.state.activeAccountId) {
      this.setState({
        offers: LSI.getOffers(this.state.activeAccountId),
      });
    }

    if (
      this.state.offers == undefined &&
      this.state.activeAccountId != undefined &&
      this.state.awaitingOffers == false &&
      this.state.errorMessage == undefined
    ) {
      this.setState({ awaitingOffers: true });
      const activeAccountId = this.state.activeAccountId;
      AppServerAPI.getXsollaOffers(
        activeAccountId,
        (offers, currencies, isSandboxMode) => {
          LSI.setOffers(offers, activeAccountId);
          LSI.setCurrencies(currencies, activeAccountId);
          LSI.setIsSandboxMode(isSandboxMode);
          this.setState({
            offers: offers,
            currencies: currencies,
            awaitingOffers: false,
          });
        }
      );
    }

    if (prevState.accounts == undefined && this.state.accounts != undefined) {
      if (this.state.timeOutNumber != undefined) {
        window.clearTimeout(this.state.timeOutNumber);
      }
      const timeOutNumber = configureLogoutTimer();
      if (timeOutNumber != undefined) {
        this.setState({ timeOutNumber: timeOutNumber });
      }
    }
  }

  subHeader = (currencies: Currencies | undefined) => {
    if (currencies != undefined) {
      return (
        <SubHeaderWrapper>
          <SubHeaderResource>
            <img src={starPointIcon} alt="" />
            <span>{currencies.loyaltyPoints}</span>
          </SubHeaderResource>
          <SubHeaderResource>
            <img src={goldIcon} alt="" />
            <span>{currencies.gold}</span>
          </SubHeaderResource>
          <SubHeaderResource>
            <img src={silverIcon} alt="" />
            <span>{currencies.silver}</span>
          </SubHeaderResource>
        </SubHeaderWrapper>
      );
    }
  };

  render() {
    const loyaltyPointStoreRefresh =
      this.state.offers?.loyaltyPointStore.currentPeriodExpiration || undefined;
    const account = parseActiveAccount(
      this.state.accounts,
      this.state.activeAccountId
    );

    return (
      <PageWrapper
        activeRoute={"/shop"}
        backgroundImage={backgroundImage}
        fullScreen={false}
        subHeader={this.subHeader(this.state.currencies)}
        forceLogin={false}
        activeAccount={account}
        accounts={this.state.accounts}
        updateAccounts={(accounts: AccountInfo[]): void => {
          this.setState({ accounts: accounts });
        }}
        updateActiveAccount={(account: AccountInfo): void => {
          this.setState({
            activeAccountId: account.accountId,
            currencies: LSI.getCurrencies(account.accountId),
            offers: LSI.getOffers(account.accountId),
          });
        }}
        removeAccountsAndActive={() =>
          this.setState({ accounts: undefined, activeAccountId: undefined })
        }
        showLogin={this.state.showLogin}
      >
        <ConfirmationPopover {...this.state.confirmationData} />
        {this.state.activeAccountId != undefined ? (
          <ContentDiv
            smallDevice={isSmallScreen()}
            hasSubHeader={true}
            fullScreen={false}
          >
            <TitleDiv>
              <h1>metalstorm shop</h1>
              <h3>
                Starform's official online marketplace for in-game Metalstorm
                products. Support the developers and get the best deals — direct
                from the source!
              </h3>
            </TitleDiv>
            {this.state.offers?.flightPassOffers.map((offer) => (
              <FlightPassSection
                offer={offer}
                purchaseFn={() => {
                  const accountId = LSI.getActiveAccountId();
                  const sandbox = LSI.getIsSandboxMode();
                  if (accountId != undefined) {
                    AppServerAPI.makeXsollaPurchase(
                      accountId,
                      offer.sku,
                      sandbox,
                      async (accessToken) =>
                        openPayStation(accessToken, sandbox, () => {
                          const previousConfirmationData =
                            this.state.confirmationData;
                          previousConfirmationData.step =
                            ConfirmationSteps.deliveredPaid;
                          previousConfirmationData.starPointsAwarded =
                            offer.loyaltyPoints;
                          this.setState({
                            confirmationData: previousConfirmationData,
                            offers: undefined,
                          });
                        }),
                      offer.uniqueId
                    );
                  }
                }}
              />
            ))}

            <LoyaltyPointImgSmall src={starPointIcon} alt="" />
            <ContentBlock id={"rewards"} title="Web Shop Rewards!">
              <LoyaltyPointHeader>
                <LoyaltyPointHeaderText>
                  <LoyaltyPointImg src={starPointIcon} alt="" />
                  <span>
                    Purchases you make on the Web Shop award{" "}
                    <AccentColor>Star Points</AccentColor>. Redeem your Star
                    Points on the Web Shop for FREE REWARDS!
                  </span>
                </LoyaltyPointHeaderText>
                {loyaltyPointStoreRefresh != undefined && (
                  <ShopTimer>
                    <TimerImg src={ExpirationTypes.expiry} />
                    <span>
                      <CustomTimer
                        expirationTime={loyaltyPointStoreRefresh}
                        serverOffset={this.state.offers?.serverOffset || 0}
                      />
                    </span>
                  </ShopTimer>
                )}
              </LoyaltyPointHeader>
              <LoyaltyCardGrid>
                {this.state.offers?.loyaltyPointStore.offers
                  .sort((a, b) => a.sortOrder - b.sortOrder)
                  .map((offer) => {
                    const activeAccount = LSI.getActiveAccountId();
                    let clickFn = () => {
                      console.log("not logged in");
                    };
                    if (activeAccount != undefined) {
                      clickFn = () => {
                        const previousConfirmationData =
                          this.state.confirmationData;
                        previousConfirmationData.step =
                          ConfirmationSteps.confirmation;
                        previousConfirmationData.starPointsRequired =
                          offer.loyaltyPointCost;
                        previousConfirmationData.itemName = offer.award.text;
                        previousConfirmationData.purchaseFn = () => {
                          if (this.state.starPointOfferProcessing == false) {
                            this.setState({ starPointOfferProcessing: true });
                            AppServerAPI.makeLoyaltyPointPurchase(
                              activeAccount,
                              offer.offerId,
                              () => {
                                const updatedConfirmationData =
                                  this.state.confirmationData;
                                updatedConfirmationData.step =
                                  ConfirmationSteps.deliveredFree;
                                updatedConfirmationData.starPointsRequired =
                                  undefined;
                                updatedConfirmationData.itemName = undefined;
                                updatedConfirmationData.purchaseFn = () => null;
                                this.setState({
                                  confirmationData: previousConfirmationData,
                                  offers: undefined,
                                  starPointOfferProcessing: false,
                                });
                              }
                            );
                          }
                        };
                        this.setState({
                          confirmationData: previousConfirmationData,
                        });
                      };
                    }
                    return (
                      <LoyaltyOfferCard
                        offer={offer}
                        pointsSpent={
                          this.state.offers?.loyaltyPointStore
                            .loyaltyPointsSpent || 0
                        }
                        purchaseFn={clickFn}
                        pointWalletTotal={parseInt(
                          this.state.currencies?.loyaltyPoints.replace(
                            ",",
                            ""
                          ) || "0"
                        )}
                      />
                    );
                  })}
              </LoyaltyCardGrid>
            </ContentBlock>
            <ContentBlock id={"dailies"} title="Daily Deals">
              <DailyOrSpecialCardGrid>
                {this.state.offers?.dailyOffers.map(
                  (offer: DailyOrSpecialOffer) => {
                    let clickFn = () => {};
                    if (offer.consumed != true) {
                      const accountId = LSI.getActiveAccountId();
                      if (offer.price.amount == 0) {
                        clickFn = () => {
                          if (accountId != undefined) {
                            AppServerAPI.makeDailyOfferPurchase(
                              accountId,
                              offer.sku,
                              async () => {
                                const previousConfirmationData =
                                  this.state.confirmationData;
                                previousConfirmationData.step =
                                  ConfirmationSteps.deliveredFree;
                                this.setState({
                                  confirmationData: previousConfirmationData,
                                  offers: undefined,
                                });
                              }
                            );
                          }
                        };
                      } else {
                        clickFn = () => {
                          const sandbox = LSI.getIsSandboxMode();
                          if (accountId != undefined) {
                            AppServerAPI.makeXsollaPurchase(
                              accountId,
                              offer.sku,
                              sandbox,
                              async (accessToken) =>
                                openPayStation(accessToken, sandbox, () => {
                                  const previousConfirmationData =
                                    this.state.confirmationData;
                                  previousConfirmationData.step =
                                    ConfirmationSteps.deliveredPaid;
                                  previousConfirmationData.starPointsAwarded =
                                    offer.loyaltyPoints;
                                  this.setState({
                                    confirmationData: previousConfirmationData,
                                    offers: undefined,
                                  });
                                }),
                              offer.uniqueId
                            );
                          }
                        };
                      }
                    }
                    return (
                      <DailyOfferCard
                        purchaseFn={() => {}}
                        offer={offer}
                        onClick={clickFn}
                      />
                    );
                  }
                )}
                {this.state.offers?.dailyOffers != undefined ? (
                  this.state.offers?.dailyOffers.length == 0 ? (
                    <PlaceholderCard
                      {...{
                        h: 315,
                        w: 685,
                        text: "Check back often to find new personalized offers!",
                        fullWidth: true,
                      }}
                    />
                  ) : this.state.offers?.dailyOffers.length % 2 != 0 ? (
                    <PlaceholderCard
                      {...{
                        h: 315,
                        w: 685,
                        text: "Check back often to find new personalized offers!",
                        fullWidth: false,
                      }}
                    />
                  ) : null
                ) : null}
              </DailyOrSpecialCardGrid>
            </ContentBlock>
            {this.state.offers != undefined &&
            this.state.offers.specialOffers != undefined &&
            this.state.offers.specialOffers.length > 0 ? (
              <ContentBlock id={"specials"} title="Special Offers">
                <DailyOrSpecialCardGrid>
                  {this.state.offers?.specialOffers.map(
                    (offer: DailyOrSpecialOffer) => {
                      return (
                        <SpecialOfferCard
                          purchaseFn={() => {}}
                          offer={offer}
                          onClick={() => {
                            const accountId = LSI.getActiveAccountId();
                            const sandbox = LSI.getIsSandboxMode();
                            if (accountId != undefined) {
                              AppServerAPI.makeXsollaPurchase(
                                accountId,
                                offer.sku,
                                sandbox,
                                async (accessToken) =>
                                  openPayStation(accessToken, sandbox, () => {
                                    const previousConfirmationData =
                                      this.state.confirmationData;
                                    previousConfirmationData.step =
                                      ConfirmationSteps.deliveredPaid;
                                    previousConfirmationData.starPointsAwarded =
                                      offer.loyaltyPoints;
                                    this.setState({
                                      confirmationData:
                                        previousConfirmationData,
                                      offers: undefined,
                                    });
                                  }),
                                offer.uniqueId
                              );
                            }
                          }}
                        />
                      );
                    }
                  )}
                  {this.state.offers?.specialOffers.length == 0 ? (
                    <PlaceholderCard
                      {...{
                        h: 315,
                        w: 685,
                        text: "Check back often to find new personalized offers!",
                        fullWidth: true,
                      }}
                    />
                  ) : this.state.offers?.specialOffers.length % 2 != 0 ? (
                    <PlaceholderCard
                      {...{
                        h: 315,
                        w: 685,
                        text: "Check back often to find new personalized offers!",
                        fullWidth: false,
                      }}
                    />
                  ) : null}
                </DailyOrSpecialCardGrid>
              </ContentBlock>
            ) : null}
            <ContentBlock id={"gold"} title="Gold">
              <GoldCardGrid>
                {this.state.offers?.goldOffers.map((offer) => {
                  let flagText = "";
                  if (offer.name == "170 Gold") {
                    flagText = "Popular";
                  } else if (offer.name == "2000 Gold") {
                    flagText = "Best Value";
                  }
                  return (
                    <GoldCard
                      flagText={flagText}
                      purchaseFn={() => {
                        const accountId = LSI.getActiveAccountId();
                        const sandbox = LSI.getIsSandboxMode();
                        if (accountId != undefined) {
                          AppServerAPI.makeXsollaPurchase(
                            accountId,
                            offer.sku,
                            sandbox,
                            async (accessToken) =>
                              openPayStation(accessToken, sandbox, () => {
                                const previousConfirmationData =
                                  this.state.confirmationData;
                                previousConfirmationData.step =
                                  ConfirmationSteps.deliveredPaid;
                                previousConfirmationData.starPointsAwarded =
                                  offer.attributes[0].values[0].value;
                                this.setState({
                                  confirmationData: previousConfirmationData,
                                  offers: undefined,
                                });
                              })
                          );
                        }
                      }}
                      loyaltyPoints={0}
                      offer={offer}
                    />
                  );
                })}
              </GoldCardGrid>
            </ContentBlock>
          </ContentDiv>
        ) : (
          <ContentDiv
            smallDevice={isSmallScreen()}
            hasSubHeader={true}
            fullScreen={false}
          >
            <TitleDiv>
              <h1>metalstorm shop</h1>
              <h3>
                Starform's official online marketplace for in-game Metalstorm
                products. Support the developers and get the best deals — direct
                from the source!
              </h3>
            </TitleDiv>
            <LoyaltyPointImgSmall src={starPointIcon} alt="" />
            <ContentBlock id={"rewards"} title="Web Shop Rewards!">
              <LoyaltyPointHeader>
                <LoyaltyPointHeaderText>
                  <LoyaltyPointImg src={starPointIcon} alt="" />
                  <span>
                    Purchases you make on the Web Shop award{" "}
                    <AccentColor>Star Points</AccentColor>. Redeem your Star
                    Points on the Web Shop for FREE REWARDS!
                  </span>
                </LoyaltyPointHeaderText>
                {loyaltyPointStoreRefresh != undefined && (
                  <ShopTimer>
                    <TimerImg src={ExpirationTypes.expiry} />
                    <span>
                      <CustomTimer
                        expirationTime={
                          FakeStore.loyaltyPointStore.currentPeriodExpiration
                        }
                        serverOffset={0}
                      />
                    </span>
                  </ShopTimer>
                )}
              </LoyaltyPointHeader>
              <LoyaltyCardGrid>
                {FakeStore.loyaltyPointStore.offers
                  .sort(
                    (a: { sortOrder: number }, b: { sortOrder: number }) =>
                      a.sortOrder - b.sortOrder
                  )
                  .map((offer: LoyaltyPointOffer) => {
                    return (
                      <LoyaltyOfferCard
                        offer={offer}
                        pointsSpent={300}
                        purchaseFn={() =>
                          this.setState({ showLogin: Date.now() })
                        }
                        pointWalletTotal={1000}
                      />
                    );
                  })}
              </LoyaltyCardGrid>
            </ContentBlock>
            <ContentBlock id={"dailies"} title="Daily Deals">
              <DailyOrSpecialCardGrid>
                {FakeStore.dailyOffers.map((offer: DailyOrSpecialOffer) => {
                  return (
                    <DailyOfferCard
                      purchaseFn={() => {}}
                      offer={offer}
                      onClick={() => this.setState({ showLogin: Date.now() })}
                    />
                  );
                })}
              </DailyOrSpecialCardGrid>
            </ContentBlock>
            <ContentBlock id={"gold"} title="Gold">
              <GoldCardGrid>
                {FakeStore.goldOffers.map((offer: GoldOffer) => {
                  let flagText = "";
                  if (offer.name == "170 Gold") {
                    flagText = "Popular";
                  } else if (offer.name == "2000 Gold") {
                    flagText = "Best Value";
                  }
                  return (
                    <GoldCard
                      flagText={flagText}
                      purchaseFn={() => {
                        this.setState({ showLogin: Date.now() });
                      }}
                      loyaltyPoints={0}
                      offer={offer}
                    />
                  );
                })}
              </GoldCardGrid>
            </ContentBlock>
          </ContentDiv>
        )}
      </PageWrapper>
    );
  }
}
